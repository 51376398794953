<template>
    <div class="balance" >
        <div class="card__item">
            <div class="tabs">
                <div class="tab">
                    <button :class=" tabStatus == 0 ? 'active': ''" @click="tabStatus = 0">Add</button>
                </div>
                <div class="tab">
                    <button :class=" tabStatus == 1 ? 'active': ''" 
                        :disabled="$store.state.account==null || $store.state.liquidity.lpToken==null" 
                        @click="tabStatus = 1">Remove
                    </button>
                </div>
            </div>
        </div>
        <div v-if="getTab == 0">
            <CurrencyField title="Input" :coin="coinOne" :max="balanceOne" :dataList="tokenList" 
                :readonly="coinTwo.name==undefined?true:false" index="0"
                @change="changeOne" @input="inputOne" v-model="valueOne" :balance="balanceOne" :compareBalance="true"/>
            <div class="flex justify-center q-my-md items-center" style="font-weight:500">
                <div>
                    <div class="swap">
                        <img src="@/assets/images/plus.png" style="width:15px">
                    </div>
                </div>
                <div class="q-mx-md" style="color:#abc4ed; font-size:0.8rem">
                    1 {{this.coinOne.symbol}} ≈ {{getRate}} {{this.coinTwo.symbol}}
                </div>
                <div class="ml-2 revert" @click="inverse" 
                    style="color:#abc4ed; font-size:0.8rem; cursor:pointer">⇋
                </div>
            </div>
            <CurrencyField title="Input" :coin="coinTwo" :max="balanceTwo" :dataList="tokenList" 
                :readonly="coinTwo.name==undefined?true:false" index="1"
                @change="changeTwo" @input="inputTwo" v-model="valueTwo" :balance="balanceTwo" :compareBalance="true"/>

            <div class="transition my-4">
                <div class="q-mx-md q-my-sm">
                    <div class="d-flex justify-content-between">
                        <h2>Pool liquidity ({{coinOne.symbol}})</h2>
                        <h3>{{getOneReserve}} {{coinOne.symbol}}</h3>
                    </div>
                    <div class="d-flex justify-content-between">
                        <h2>Pool liquidity ({{coinTwo.symbol}})</h2>
                        <h3>{{getTwoReserve}} {{coinTwo.symbol}}</h3>
                    </div>
                    <div class="d-flex justify-content-between">
                        <h2>LP supply</h2>
                        <h3>{{getTotalLPSupply}} LP</h3>
                    </div>
                    <div class="d-flex justify-content-between">
                        <h2>Your LP supply</h2>
                        <h3>{{getYourLPSupply}} LP</h3>
                    </div>
                </div>
            </div>
            <div class="walletBtn" v-if="!isMetaMaskConnected" @click="connectWallet">Connect Wallet</div>
            <div class="walletBtn" v-if="isMetaMaskConnected && (!isOneTokenApproved || !isTwoTokenApproved)" 
                @click="approveAdd">Approve
            </div>
            <div class="walletBtn" v-if="isMetaMaskConnected && isOneTokenApproved && isTwoTokenApproved" 
                :disabled="((coinOne.symbol == 'ASTR' && coinTwo.symbol == 'WASTR') 
                        || (coinOne.symbol == 'WASTR' && coinTwo.symbol == 'ASTR'))"
                @click="addLiquidity" >Add Liquidity
            </div>
        </div>
        <div v-else>
            <InputField title="Amount to Remove (%)" :disable="isApprovedRemove" v-model="removeValue" 
                @input="inputRemove" :balance="balanceLP" class="border-radius:5px;"></InputField>
                <div class="items-center justify-between bg-secondary rounded-borders q-px-md q-py-md q-my-md">
                    <div style="width:100%">
                        <div style="font-size:0.8rem; color: rgb(171, 196, 237);font-weight:500">You Will Receive  
                            <span style="color:#17a2b8!important">{{coinOne.symbol}} And {{coinTwo.symbol}}</span></div>
                    </div>
                    <div class="row q-px-md q-my-md rounded-borders text-white " style="margin:0; padding:0">
                        <div class="col-12 col-md-6 flex items-center q-mt-sm part1 sol"
                           >
                            <span class="q-px-sm avatar q-mx-sm">
                                <img :src="coinOne.logo" width="25px">
                            </span>
                            <div class="text-bold">
                                <div><span style="font-size:0.8rem;">{{receiveOne}}</span></div>
                                <div style="font-size:0.8rem; color:rgb(255 255 255 / 50%)">{{coinOne.symbol}}</div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 flex items-center q-mt-sm part2 sol" 
                            style="padding:4px; background:rgb(69 159 233 / 25%);">
                            <span class="q-px-sm avatar q-mx-sm">
                                <img :src="coinTwo.logo" width="25px">
                            </span>
                            <div class="text-bold">
                                <div><span style="font-size:0.8rem;">{{receiveTwo}}</span></div>
                                <div style="font-size:0.8rem; color:rgb(255 255 255 / 50%)">{{coinTwo.symbol}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="walletBtn" v-if="!isMetaMaskConnected" @click="connectWallet">Connect Wallet</div>
            <div class="walletBtn" v-if="isMetaMaskConnected && !isApprovedRemove" @click="approveRemove">Approve</div>
            <div class="walletBtn" v-if="isMetaMaskConnected && isApprovedRemove" @click="removeLiquidity">Remove</div>
        </div>
    </div>
</template>

<script>
    import CurrencyField from '@/components/currency/CurrencyField'
    import InputField from "./InputField";
    import { BigNumber } from 'bignumber.js'
    import {token_list} from '@/constants/token.js'

    export default {
        components: {
            InputField,
            CurrencyField,
        },
        props: {
            tab: null,
            token0: null,
            token1: null
        },
        data() {
            return {
                coinOne: {},
                coinTwo: {},
                valueOne: '',
                valueTwo: '',
                baseCoin: 0,
                removeValue: null,
                tabStatus: null,
                updateInterval: null
            }
        },
        computed: {
            getTab() {
                return this.tabStatus;
            },
            isMetaMaskConnected() {
                return this.$store.state.account!=null;
            },
            isOneTokenApproved() {
                return this.$store.state.approvedOneTokenRouter;
            },
            isTwoTokenApproved() {
                return this.$store.state.approvedTwoTokenRouter;
            },
            getRateOne2Two() {
                let rate = BigNumber(1);

                if (((this.coinOne.symbol == 'ASTR' && this.coinTwo.symbol == 'WASTR') 
                        || (this.coinOne.symbol == 'WASTR' && this.coinTwo.symbol == 'ASTR')))
                    return rate;

                if (this.$store.state.liquidity.amountOne > 0 
                    && this.$store.state.liquidity.amountTwo > 0) {
                        let decimals0 = this.coinOne.decimals * (-1);
                        let decimals1 = this.coinTwo.decimals * (-1);
                    rate = BigNumber(this.$store.state.liquidity.amountTwo).shiftedBy(decimals1)
                        .div(BigNumber(this.$store.state.liquidity.amountOne).shiftedBy(decimals0))
                    }
                else {
                    rate = BigNumber(0);
                }

                return rate;
            },
            getRate() {
                return this.getDecimalData(this.getRateOne2Two);
            },
            receiveOne() {
                let liquidity = this.$store.state.liquidity;
                if(liquidity.balance && this.removeValue) {
                    var removeValue = parseFloat(this.removeValue);
                    if(removeValue>100)
                        removeValue = 100;
                    else if(removeValue<0)
                        removeValue = 0;

                    let decimals = this.coinOne.decimals * (-1);
                    let value = BigNumber(liquidity.amountOne)
                        .times(BigNumber(liquidity.balance).times(BigNumber(removeValue).div(100)))
                        .div(BigNumber(liquidity.totalSupply))
                        .shiftedBy(decimals);

                    return this.getDecimalData(value);
                }
                return "-";
            },
            receiveTwo() {
                let liquidity = this.$store.state.liquidity;
                if(liquidity.balance && this.removeValue) {
                    var removeValue = parseFloat(this.removeValue);
                    if(removeValue>100)
                        removeValue = 100;
                    else if(removeValue<0)
                        removeValue = 0;

                    let decimals = this.coinTwo.decimals * (-1);
                    let value = BigNumber(liquidity.amountTwo)
                        .times(BigNumber(liquidity.balance).times(BigNumber(removeValue).div(100)))
                        .div(BigNumber(liquidity.totalSupply))
                        .shiftedBy(decimals);
                    
                    return this.getDecimalData(value);
                }
                return "-";
            },
            amountRemove() {
                let liquidity = this.$store.state.liquidity;
                if(liquidity.balance && this.removeValue) {
                    var removeValue = parseFloat(this.removeValue);
                    if(removeValue>100)
                        removeValue = 100;
                    else if(removeValue<0)
                        removeValue = 0;
                    return BigNumber(liquidity.balance)
                        .times(removeValue)
                        .div(100)
                        .shiftedBy(-18);
                }
                return "0";
            },
            balanceOne() {
                if (this.coinOne.symbol) {
                    let decimals = this.coinOne.decimals * (-1);
                    return BigNumber(this.$store.state.balance.amountFrom).shiftedBy(decimals);
                }
                else {
                    return BigNumber(0);
                }
            },
            balanceTwo() {
                if (this.coinTwo.symbol) {
                    let decimals = this.coinTwo.decimals * (-1);
                    return BigNumber(this.$store.state.balance.amountTo).shiftedBy(decimals);
                }
                else {
                    return BigNumber(0);
                }
            },
            balanceLP() {
                return BigNumber(this.$store.state.liquidity.balance).shiftedBy(-18);
            },
            isApprovedRemove() {
                return this.$store.state.approvedPairRouter;
            },
            getOneReserve() {
                if (this.$store.state.liquidity.amountOne > 0) {
                    let decimals = this.coinOne.decimals * (-1);
                    return this.getDecimalData(BigNumber(this.$store.state.liquidity.amountOne)
                        .shiftedBy(decimals));
                }

                return '0.00';
            },
            getTwoReserve() {
                if (this.$store.state.liquidity.amountTwo > 0) {
                    let decimals = this.coinTwo.decimals * (-1);
                    return this.getDecimalData(BigNumber(this.$store.state.liquidity.amountTwo)
                        .shiftedBy(decimals));
                }

                return '0.00';
            },
            getTotalLPSupply() {
                if (this.$store.state.liquidity.totalSupply > 0) {
                    return this.getDecimalData(BigNumber(this.$store.state.liquidity.totalSupply)
                        .shiftedBy(-18));
                }

                return '0.00';
            },
            getYourLPSupply() {
                if (this.$store.state.liquidity.balance > 0) {
                    return this.getDecimalData(BigNumber(this.$store.state.liquidity.balance)
                        .shiftedBy(-18));
                }

                return '0.00';
            },
        },
        mounted() {
            this.tabStatus = this.tab;
            this.tokenList = token_list;

            this.coinOne = this.$store.state.liquidity.tokenMap.get(this.token0);
            this.coinTwo = this.$store.state.liquidity.tokenMap.get(this.token1);

            this.$store.dispatch("changeTokenProcess",
                {
                    coinOne: this.coinOne,
                    coinTwo: this.coinTwo,
                    liquidity: true,
                    decimalChanged: false
                }
            );

            this.updateInterval = setInterval(()=>{
                this.updateValues();
            },15000)
        },
        destroyed() {
            clearInterval(this.updateInterval);
        },
        methods: {
            connectWallet() {
                this.$store.dispatch('connect');
            },
            changeOne(item) {
                if (this.coinTwo === item) {
                    this.inverse();
                }
                else {
                    let decimalChanged;
                    if (this.coinOne.decimals != item.decimals)
                        decimalChanged = true;
                    else
                        decimalChanged = false;
                    this.coinOne = item;
                    this.$store.dispatch("changeTokenProcess",
                        {
                            coinOne: this.coinOne,
                            coinTwo: this.coinTwo,
                            liquidity: true,
                            decimalChanged: decimalChanged
                        }
                    );

                    this.updateValues();

                    // this.valueOne = "0.0";
                    // this.valueTwo = "0.0";
                }
            },
            changeTwo(item) {
                if (this.coinOne === item) {
                    this.inverse();
                }
                else {
                    let decimalChanged;
                    if (this.coinTwo.decimals != item.decimals)
                        decimalChanged = true;
                    else
                        decimalChanged = false;
                    this.coinTwo = item;
                    this.$store.dispatch("changeTokenProcess",
                        { 
                            coinOne: this.coinOne,
                            coinTwo: this.coinTwo,
                            liquidity: true,
                            decimalChanged: decimalChanged
                        }
                    );

                    this.updateValues();

                    // this.valueOne = "0.0";
                    // this.valueTwo = "0.0";
                }
            },
            inputOne(value) {
                let rate = this.getRateOne2Two;

                this.baseCoin = 1;
                if (rate.isGreaterThan(BigNumber(0))) {
                    if(value) {
                        this.valueTwo = this.getDecimalData(BigNumber(value).times(rate));
                    } else
                        this.valueTwo = "";
                }
            },
            inputTwo(value) {
                let rate = this.getRateOne2Two;

                this.baseCoin = 2;
                if (rate.isGreaterThan(BigNumber(0))) {
                    if(value) {
                        this.valueOne = this.getDecimalData(BigNumber(value).div(rate));
                    } else
                        this.valueOne = "";
                }
            },
            updateValues() {
                if (this.baseCoin == 1) {
                    let value = this.valueOne;
                    let rate = this.getRateOne2Two;

                    if (rate.isGreaterThan(BigNumber(0))) {
                        if(value) {
                            this.valueTwo = this.getDecimalData(BigNumber(value).times(rate));
                        } else
                            this.valueTwo = "";
                    }
                }
                else if (this.baseCoin == 2) {
                    let value = this.valueTwo;
                    let rate = this.getRateOne2Two;

                    this.baseCoin = 2;
                    if (rate.isGreaterThan(BigNumber(0))) {
                        if(value) {
                            this.valueOne = this.getDecimalData(BigNumber(value).div(rate));
                        } else
                            this.valueOne = "";
                    }
                }
            },
            inputRemove(value) {
                if(value=="")
                    return;
            },
            inverse() {
                let itemOne = this.coinOne;
                let valueOne = this.valueOne;
                this.coinOne = this.coinTwo;
                this.valueOne = this.valueTwo;
                this.coinTwo = itemOne;
                this.valueTwo = valueOne;

                let decimalChanged;
                if (this.coinOne.decimals != this.coinTwo.decimals)
                    decimalChanged = true;
                else
                    decimalChanged = false;
                this.$store.dispatch("changeTokenProcess",
                    {
                        coinOne: this.coinOne,
                        coinTwo: this.coinTwo,
                        liquidity: true,
                        decimalChanged: decimalChanged
                    }
                );
            },
            addLiquidity() {
                this.$store.dispatch('add_liquidity',{
                    tokenOne: this.coinOne,
                    amountOne: this.valueOne,
                    tokenTwo: this.coinTwo,
                    amountTwo: this.valueTwo * 1.000001     // Not sure why 1.00001 is needed.
                });
            },
            fee() {
                if(this.valueTwo) {
                    return BigNumber(this.valueTwo).times(0.01).toFixed(5);
                }
                return 0;
            },
            approveRemove() {
                this.$store.dispatch('approvePairRouter');
            },
            async approveAdd() {
                if (!this.isOneTokenApproved)
                    await this.$store.dispatch('approveRouter', {
                        tokenAddress: this.coinOne.address,
                        index: 0
                    });

                if (!this.isTwoTokenApproved)
                    await this.$store.dispatch('approveRouter', {
                        tokenAddress: this.coinTwo.address,
                        index:1
                    });
            },
            removeLiquidity() {
                if(this.isApprovedRemove)
                    this.$store.dispatch('removeLiquidity',
                    {
                        tokenOne: this.coinOne,
                        tokenTwo: this.coinTwo,
                        amount: this.amountRemove});
            },
            getDecimalData(value) {
                let limit_xxx = BigNumber(1000);
                let limit_xx = BigNumber(1);
                let limit_x = BigNumber(1).shiftedBy(-3);
                let limit_sm = BigNumber(1).shiftedBy(-6);
                let limit_md = BigNumber(1).shiftedBy(-9);
                let limit_lg = BigNumber(1).shiftedBy(-12);

                if (limit_lg.isGreaterThan(value)) {
                    return value.toString();
                }
                else if (limit_md.isGreaterThan(value)) {
                    return value.decimalPlaces(12, 1).toString();
                }
                else if (limit_sm.isGreaterThan(value)) {
                    return value.decimalPlaces(9, 1).toString();
                }
                else if (limit_x.isGreaterThan(value)) {
                    return value.decimalPlaces(6, 1).toString();
                }
                else if (limit_xx.isGreaterThan(value)) {
                    return value.decimalPlaces(4, 1).toString();
                }
                else if (limit_xxx.isGreaterThan(value)) {
                    return value.decimalPlaces(2, 1).toString();
                }
                else {
                    return value.decimalPlaces(0, 1).toString();
                }
            }
        }
    };
</script>

<style scoped>
    .card__item {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .card__item:first-child {
        padding-bottom: 15px;
    }
    .tabs {
        width: 100%;
        display: flex;
        border-radius: 10px;
        padding: 2px;
        background: var(--q-color-secondary)
    }
    .tab {
        flex: 1;
        /* display: inline; */
    }
    .tab > button {
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 7px;
        width: 100%;
        color: #999;
        border: none;
        cursor: pointer;
    }
    .tab > .active {
        background-image: linear-gradient(to right, var(--q-color-primary), var(--q-color-info));
        color: #fff;
        font-weight: 600;
    }

    .bg-secondary {
        background: #42a1e821 !important;
        border-radius: 10px;
    }

    .h3 {
        color:#abc4ed;
    }

    .bg-dark {
        background: #1d1d1d!important;
        background: var(--q-color-dark)!important;
        color:white;
    }

    .revert {
        color: rgba(171,196,255,.5) !important;
        font-size:1rem;
        cursor:pointer
    }

    .revert:hover {
        color:#ace3e5 !important;
    }

    .part1 {
        padding:4px; 
        background:rgb(69 159 233 / 25%);
        border-right:1px solid #1e306c;
        border-radius: 10px 0 0 10px;
    }

    .part2 {
        padding:4px; 
        background:rgb(69 159 233 / 25%);
        border-left:1px solid #1e306c;
        border-radius: 0 10px 10px 0;
    }

    @media(max-width: 996px) {
        .part1 {
            border-right:none;
            border-radius: 10px;
        }

        .part2 {
            border-left:none;
            border-radius: 10px;
        }
    }
</style>