<template>
    <div class="root">
        <div class="d-flex justify-content-between">
            <h4></h4>
            <h5 @click="setPercent(3)" class="balanceBtn">Balance: {{balanceValue}} LP</h5>
        </div>
        <div class="d-flex">
            <div class="root__item root__item--small" 
                style="font-size:0.8rem; color: rgb(171, 196, 237);">
                {{title}}
            </div>
            <div class="root__item ml-2 remove sol" style="border-radius:5px;">
                <q-input 
                    standout="bg-indigo-5" type="text" 
                    placeholder="0.00" v-model="mutableValue"
                    :error-message="errorMessage" :error="errorMessage!=null" :bottom-slots="false" :hide-bottom-space="true">
                    <template v-slot:prepend>
                    <div class="dropdown">
                        <button @click="showPercent()" 
                            class="dropbtn mx-2 dropdown-toggle">
                            {{currentPercent}}%</button>
                        
                        <div id="percent" class="percent dropdown-content" 
                            v-click-outside="onClickOutside1">
                            <ul>
                                <li @click="setPercent(0)">25%</li>
                                <li @click="setPercent(1)">50%</li>
                                <li @click="setPercent(2)">75%</li>
                                <li @click="setPercent(3)">100%</li>
                            </ul>
                        </div>
                    </div>
                    </template>
                </q-input>
            </div>
        </div>
    </div>
</template>
<script>
    import BigNumber from 'bignumber.js'
    export default {
        name: 'InputField',
        data() {
            return {
                selectedCoin: null,
                currentValue: 0,
                percents: [
                    '25', '50', '75', '100'
                ],
                currentPercent: 100
            };
        },
        props: {
            title: {type: String},
            value: {type: String},
            max: null,
            balance: null
        },
        computed: {
            mutableValue: {
                get: function() {
                    return this.value;
                },
                set: function(value) {
                    this.$emit('input',value);
                }
            },  
            errorMessage() {
                if(this.value > 100) {
                    return "INSUFFICIENT ACCOUNT BALANCE";
                }
                return null;
            },
            balanceValue() {
                if(this.balance > 0)
                    return this.getDecimalData(BigNumber(this.balance));

                return '0.00';
            }                 
        },
        methods: {
            changeValue(event) {
                var value = event.target.value;
                this.$emit('input',value);
            },
            updateValue(e) {
                if(this.errorMessage==null)
                    this.$emit('input',e.target.value);
            },
            setMax() {
                this.mutableValue = '100.0';
            },
            showPercent() {
                document.getElementById("percent").classList.toggle("show");
            },
            setPercent(value) {
                this.currentPercent = this.percents[value];

                if (value == 0)
                    this.mutableValue = '25';
                else if (value == 1)
                    this.mutableValue = '50';
                else if (value == 2)
                    this.mutableValue = '75';
                else
                    this.mutableValue = '100';

                 document.getElementById("percent").classList.toggle("show");
            },
            onClickOutside1 (event) {
                if (!event.target.matches('.dropbtn')) {
                    let dropdowns = document.getElementsByClassName("dropdown-content");
                    let i;
                    for (i = 0; i < dropdowns.length; i++) {
                        let openDropdown = dropdowns[i];
                        if (openDropdown.classList.contains('show')) {
                            openDropdown.classList.remove('show');
                        }
                    }
                }
            },
            getDecimalData(value) {
                let limit_xxx = BigNumber(1000);
                let limit_xx = BigNumber(1);
                let limit_x = BigNumber(1).shiftedBy(-3);
                let limit_sm = BigNumber(1).shiftedBy(-6);
                let limit_md = BigNumber(1).shiftedBy(-9);
                let limit_lg = BigNumber(1).shiftedBy(-12);

                if (limit_lg.isGreaterThan(value)) {
                    return value.toString();
                }
                else if (limit_md.isGreaterThan(value)) {
                    return value.decimalPlaces(12, 1).toString();
                }
                else if (limit_sm.isGreaterThan(value)) {
                    return value.decimalPlaces(9, 1).toString();
                }
                else if (limit_x.isGreaterThan(value)) {
                    return value.decimalPlaces(6, 1).toString();
                }
                else if (limit_xx.isGreaterThan(value)) {
                    return value.decimalPlaces(4, 1).toString();
                }
                else if (limit_xxx.isGreaterThan(value)) {
                    return value.decimalPlaces(2, 1).toString();
                }
                else {
                    return value.decimalPlaces(0, 1).toString();
                }
            }
        }
    };
</script>

<style scoped>
    input {
        outline: none;
    }

    .root {
        width: 100%;
        padding: 16px;
        background: #42a1e821 !important;
        border-radius: 10px;
        justify-content: space-between;
        color:white;
        font-size: 0.8rem;
        /* align-items: center; */
    }


    @media (max-width: 600px) {
        .root {
            flex-direction: column;
        }

        .root__item--small {
            max-width: 100%;
        }
    }

    .input-text {
        width: 100%;
        font-weight: 700;
        /* padding: 10px; */
        /* border-radius: 10px; */
        font-size: 1.5rem;
        color: rgb(249 249 249 / 87%);
        /* background-color: var(--q-color-dark); */
        background: transparent;
        border: none;
    }

    .remove >>> .q-field__control {
        /* background: none; */
    }

    .remove >>> .q-field__native{
        color: rgb(249 249 249 / 87%);
        padding-right:10px;
    }

    .remove .row {
        padding:0 !important;
        margin:0 !important;
    }

    .remove >>> .q-field .row {
        padding:0 !important;
        margin:0 !important;
    }

    .remove >>> .q-field .col {
        padding:0 !important;
        margin:0 !important;
    }

    .remove button {
        padding: 2px 5px;
        background-color: #1a1554;
        color: rgba(171,196,255,.5);
        border: none;
        font-size: 12px;
        border-radius: 5px;
        margin: 0;
    }
</style>