<template>
    <section id="workingArea">
      <div class="swapBtn d-flex  align-items-center">
            <router-link :to="getSwapURL">
              Swap
            </router-link>
            <router-link :to="getLiquidityURL" class="btn2">
              Liquidity
            </router-link>
      </div>
        <div id="tabSec">
            <Liquidity :tab="kind" :token0="token0" :token1="token1" @set-fee="fee = $event" />
        </div>
    </section>
</template>
<script>
    import Liquidity from '@/components/currency/Liquidity.vue';
    export default {
        components: {
            Liquidity
        },
        data() {
            return {
                fee: 0
            }
        },
        computed: {
            kind() {
                if (this.$route.params.tabid == null)
                    return "0";

                return this.$route.params.tabid
            },
            token0() {
                if (this.$route.params.token0 == null)
                    return "ASTR";

                return this.$route.params.token0
            },
            token1() {
                if (this.$route.params.token1 == null)
                    return "STAR";

                return this.$route.params.token1
            },
            getLiquidityURL() {
                return "/liquidity/0/" + this.$store.state.liquidity.coinOne.symbol + "/" + this.$store.state.liquidity.coinTwo.symbol
            },
            getSwapURL() {
                return "/swap/" + this.$store.state.liquidity.coinOne.symbol + "/" + this.$store.state.liquidity.coinTwo.symbol
            },
        }
    }
</script>